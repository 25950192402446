import { IPermissionGateItem } from '@/types';
import { filterUserPermissions } from '@/utils/Misc';
import { useAuth } from '../Auth';
import { useEffect, useState } from 'react';
import { useBusinessUnit } from '../BusinessUnit';

const usePermissionGate = <T = any>(moduleList: IPermissionGateItem[]) => {
  const { user } = useAuth();
  const { selectedBusinessUnit } = useBusinessUnit();

  const userData = user?.data;

  const [list] = useState(moduleList);

  const [filteredList, setFilteredList] = useState(
    filterUserPermissions(list, userData, selectedBusinessUnit)
  );

  useEffect(() => {
    setFilteredList(
      filterUserPermissions(list, userData, selectedBusinessUnit)
    );
  }, [list, selectedBusinessUnit, userData]);

  return filteredList as T[];
};

export default usePermissionGate;
